<div >
<ul style="border: 1px solid #fff;">
    <li><a routerLink="/home">Home</a></li>
    <li><a routerLink="/about-us">About Us</a></li>
    <li><a routerLink="/archive">Archive</a></li>
    <li><a routerLink="/editorial-board">Editorial Board</a></li>
    <li><a href="https://www.sosb-bd.org/profile/article-submission" target="_blank">Article Submission</a></li>
    <li><a href="https://www.sosb-bd.org/auth/sign-in" target="_blank">Login</a></li>
    <li><a routerLink="/contact-us">Contact Us</a></li>
  </ul>

</div>

