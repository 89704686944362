<div class=" mt-0 body shadow" *ngIf="article$ | async as article">
  <div class="header">{{ article.title }}</div>
  <div class="p-4">
    <div class="content-body">
      <div class="col-8 left-content">
        <div class="user-name d-flex gap-2 flex-wrap" *ngIf="article?.authors">
          <ul style="list-style: none; padding: 0;">
            <li *ngFor="let author of article?.authors" class="author-card">
              <!-- <div>
                <img class="avatar" [src]="author?.photoUrl | image:'/assets/images/user-default.jpeg'" alt="author?.name">
              </div> -->
              <div>
                <p class="user-name">{{author?.name}}</p>
                <p class="desc-text">{{ author?.designationName && author.designationName + ', '}} {{
                  author?.workplaceName && author.workplaceName + ', '}} <span *ngIf="author.email">Email: {{author.email}}</span> </p>
              </div>
            </li>
          </ul>
        </div>

        <div *ngIf="article?.doi">
          <p><b>DOI: </b><a class="link">{{ article.doi }}</a></p>
        </div>

        <div class="mb-4 mt-2 d-flex gap-1 flex-wrap" *ngIf="article?.keywords">
          <b>Keywords:</b>
          <p-chip *ngFor="let keyword of article?.keywords?.split(',')">{{ keyword }}</p-chip>
        </div>
        <div>
          <p><b>Abstract</b></p>
          <div [innerHTML]="article?.abstract" style="text-align:justify"></div>

          <ng-container *ngIf="article.isOpen && article.description">
            <p><b>Description</b></p>
            <div [innerHTML]="article?.description" style="text-align:justify"></div>
          </ng-container>
        </div>
        <div *ngIf="article.isOpen" class="mt-4 d-grid gap-2">
          <b>Downloads</b>
          <div class="d-flex gap-2">
            <a download *ngIf="article.abstractFileUrl" [href]="article.abstractFileUrl | image" class="button"
              style="width: fit-content; padding: 10px 50px"> Abstract PDF</a>
            <a download *ngIf="article.isOpen" [href]="article.fileUrl | image" class="button"
              style="width: fit-content; padding: 10px 50px"> Description PDF</a>
          </div>
        </div>
      </div>

      <div class="right-content col-4">
        <div class="section d-flex gap-2">
          <a class="pointer-cursor file" download *ngIf="article.abstractFileUrl"
            [href]="article.abstractFileUrl | image"><i class="fa-regular fa-file-powerpoint "></i> Abstract PDF</a>
          <a class="pointer-cursor file" download *ngIf="article.isOpen" [href]="article.fileUrl | image"><i
              class="fa-regular fa-file-powerpoint "></i> Description PDF</a>
        </div>
        <div class="section">
          <p>Published Date<br>
            {{ (article.publishedOn | date:'dd-mm-yyyy') ?? '--' }}</p>
        </div>

        <div class="section">
          <p style="color:gray">How to Cite</p>
          <p style="font-size: 11px;">Follow proper citation guidelines by including author names, publication dates...
            etc</p>
        </div>

        <div class="section">
          <p style="color:gray;margin: 0px;">Issue</p>
          <p style="font-size: 14px;">{{ article?.issue ?? '--' }}</p>
          <p style="color:gray;margin: 0px;">Section</p>
          <p style="font-size: 14px;">{{ article?.section ?? '--' }}</p>
        </div>
      </div>

    </div>
  </div>


</div>