import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { EditorialMember, EditorialMemberDesignationType, } from '../models/member/editorial-member-response';
import {ApiService} from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class EditorialMembersService  {
  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) {
  }

  getEditorialMembers(): Observable<{ [key in EditorialMemberDesignationType]: EditorialMember[] }> {
    return this.http.get<Array<EditorialMember>>(`${this.apiService.getAPIConfig()?.baseUrl}/api/CommitteeMember/GetEditorialMembers`)
      .pipe(
        map((data) => {
          let groupedMembers: { [key in EditorialMemberDesignationType]: EditorialMember[] } = {
            "Chairman": [],
            "Editor in Chief": [],
            "Executive Editor": [],
            "Managing Editor": [],
            "Asst. Editors": [],
            "Members": [],
          };
          data.forEach(item => {
            if (!groupedMembers[item.designationName]) {
              groupedMembers[item.designationName] = [];
            }
            groupedMembers[item.designationName].push(item);
          });
          return groupedMembers;
        })
      );
  }
}
