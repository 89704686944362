import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './public/landing-page/landing-page.component';
import { AboutUsComponent } from './public/about-us/about-us.component';
import { ArchiveComponent } from './public/archive/archive.component';
import { EditorialBoardComponent } from './public/editorial-board/editorial-board.component';
import { ArticleSubmissionComponent } from './public/article-submission/article-submission.component';
import { ContactUsComponent } from './public/contact-us/contact-us.component';
import { LoginComponent } from './public/login/login.component';
import { JournalViewComponent } from './public/journal-view/journal-view.component';
import { AirticleViewComponent } from './public/airticle-view/airticle-view.component';

const routes: Routes = [
  { path: '', component: LandingPageComponent },
  { path: 'home', component: LandingPageComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'archive', component: ArchiveComponent },
  { path: 'editorial-board', component: EditorialBoardComponent },
  { path: 'article-submission', component: ArticleSubmissionComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'login', component: LoginComponent },
  { path: 'journal-view/:id',component:JournalViewComponent},
  { path: 'article-view/:id',component:AirticleViewComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
