import {IDataTablePagination} from "./DataTable/IDataTablePagination";
import {LazyLoadEvent} from "primeng/api/lazyloadevent";
import {DataTableUtility} from "./DataTable/DataTableUtility";
import {OperationTypeEnum} from "../../shared/enums/operation-type-enum";
import { Currency } from "../models/currency.model";
import { CurrencyEnum } from "src/app/shared/enums/currency.enum";

export class Utility {
  static MinOperationDate=new Date(1950,0,1);
  static GuidEmptyValue='00000000-0000-0000-0000-000000000000';

  static IsObject(data: any): boolean {
    if (data === null) {
      return false;
    }
    return (typeof data === 'object');
  }

  static HasNoError(data: any): boolean {
    if (data || data === 0) {
      return true;
    }
    return !(data == null || data === '' || data === undefined);

  }

  //#endregion DATE

  static HasAnyError(data: any): boolean {
    return !Utility.HasNoError(data);
  }
  static ThrowError(msg: string): any {
    throw new Error(msg);
  }
  static HasItemInArray(data: any): boolean {
    return data && Array.isArray(data) && data.length > 0;

  }

  static IsValidDate(date: any): any {
    return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
  }

  static ConvertToFormData<T>(formValue: T | any, form: FormData = null, namespace = ''): FormData {
    const formData = form || new FormData();
    if (!this.IsObject(formValue)) {
      return formData;
    }

    for (const key of Object.keys(formValue)) {
      const value = formValue[key];
      const formKey = namespace ? `${namespace}[${key}]` : key;
      if (this.HasNoError(value)) {
        if ((formValue[key] instanceof Date) && this.IsValidDate(value)) {
          const date = (new Date(value)).toLocaleDateString();
          formData.append(formKey, date);
        } else if (typeof formValue[key] === 'object' && this.IsValidDate(value._d)) {
          const date = (new Date(value._d)).toLocaleDateString();
          formData.append(formKey, date);
        } else {
          // tslint:disable-next-line: deprecation
          if ((formValue[key] instanceof Array) && this.HasItemInArray(value)) {

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            formValue[key].forEach((element, index) => {
              const tempFormKey = `${formKey}[${index}]`;
              if (element instanceof File) {
                formData.append(formKey, element); // dont change formKey to tempFormKey.
              } else {
                if (element && !this.IsObject(element)) {
                  formData.append(tempFormKey, element.toString());
                } else if (typeof element === 'object') {
                  this.ConvertToFormData(element, formData, tempFormKey);
                }
              }
            });

          } else if (typeof formValue[key] == 'object' && !(formValue[key] instanceof Date) && !(formValue[key] instanceof File) && !this.HasNoError(formValue[key]._d)) {
            this.ConvertToFormData(formValue[key], formData, formKey);
          } else if (formKey && formValue[key] instanceof File) {
            const newKeyForFileObject = formKey.replace('[' + key + ']', '.' + key);
            formData.append(newKeyForFileObject, value);
          } else {
            formData.append(formKey, value);
          }
        }
      } else {
        formData.append(formKey, '');
      }

    }

    return formData;
  }


  static GetDtModelData(searchVm: IDataTablePagination<any, any>, event: LazyLoadEvent): any {
    return DataTableUtility.GetDtModelData(searchVm, event);
  }

  static GetOperationTypeName(operationTypeId: number): string {
    if (!(operationTypeId > 0)) return "--";

    if (operationTypeId === OperationTypeEnum.MsmMember) return "Member";
    if (operationTypeId === OperationTypeEnum.MsmConfRegistration) return "Conf./ Event Registration";
    if (operationTypeId === OperationTypeEnum.MsmConfRegPayment) return "Conf./ Event Registration Payment";
    return "--";

  }



//  static GetTopCurrencies(){
//   return GetTopCurrencies();
//  }

//   static GetCurrency(currencyId: number){
//     return GetTopCurrencies().find(c=>c.id === currencyId);
//   }




  static getWordCountFromSentence(sentence: string): number {
    return sentence.split(/(\s+)/).filter((x) => x.trim().length>0).length

   }



static groupBy<T>(arr: T[], keySelector: (item: T) => string | number): GroupedItem<T>[] {
    return arr.reduce((result, item) => {
      const key = keySelector(item);
      const group = result.find((g) => g.key === key);

      if (group) {
        group.items.push(item);
      } else {
        result.push({ key, items: [item] });
      }

      return result;
    }, [] as GroupedItem<T>[]);
  }


}


interface GroupedItem<T> {
  key: string | number;
  items: T[];
}

function getEnumKeyByValue(enumObj: any, value: any): string | undefined {
  for (const key in enumObj) {
    if (enumObj[key] === value) {
      return key;
    }
  }
  return undefined;
}


function GetTopCurrencies(): Currency[]{
  return [
    new Currency(CurrencyEnum.BDT, getEnumKeyByValue(CurrencyEnum, CurrencyEnum.BDT), 'BD Taka', '৳'),
    new Currency(CurrencyEnum.USD, getEnumKeyByValue(CurrencyEnum, CurrencyEnum.USD), 'US Dollar', '$'),
    new Currency(CurrencyEnum.GBP, getEnumKeyByValue(CurrencyEnum, CurrencyEnum.GBP), 'GB Pound/ Sterling', '£'),
    new Currency(CurrencyEnum.EUR, getEnumKeyByValue(CurrencyEnum, CurrencyEnum.EUR), 'Euro', '€'),
    new Currency(CurrencyEnum.JPY, getEnumKeyByValue(CurrencyEnum, CurrencyEnum.JPY), 'Japanese Yen', '¥'),
    new Currency(CurrencyEnum.AUD, getEnumKeyByValue(CurrencyEnum, CurrencyEnum.AUD), 'Austrailian Dollar', 'A$'),
    new Currency(CurrencyEnum.CAD, getEnumKeyByValue(CurrencyEnum, CurrencyEnum.CAD), 'Canadian Dollar', 'C$'),
    new Currency(CurrencyEnum.CHF, getEnumKeyByValue(CurrencyEnum, CurrencyEnum.CHF), 'Swiss Franc', 'CHF'),
    new Currency(CurrencyEnum.CNH, getEnumKeyByValue(CurrencyEnum, CurrencyEnum.CNH), 'Chinese Renminbi', 'CNH'),
    new Currency(CurrencyEnum.HKD, getEnumKeyByValue(CurrencyEnum, CurrencyEnum.HKD), 'Hong Kong Dollar', 'HK$'),
    new Currency(CurrencyEnum.NZD, getEnumKeyByValue(CurrencyEnum, CurrencyEnum.NZD), 'New Zealand Dollar', 'NZ$'),
    new Currency(CurrencyEnum.SKW, getEnumKeyByValue(CurrencyEnum, CurrencyEnum.SKW), 'South Korean Won', '₩'),
  ]
}
