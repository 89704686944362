import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, map, mergeMap, Observable, of, tap } from 'rxjs';
import { LoginRequest } from '../models/auth/login-request';
import { LoginResponse } from '../models/auth/login-response';
import { User } from '../models/auth/user';
import { Member } from '../models/member/member';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  isLoggedIn = new BehaviorSubject<boolean>(false);
  nextPage!: string;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private router: Router
  ) {}

  login(
    loginRequest: LoginRequest,
    nextPage?: string
  ): Observable<LoginResponse> {
    return this.http
      .post<LoginResponse>(
        this.apiService.getEndpointUrl('login'),
        loginRequest
      )
      .pipe(
        tap((response) => this.setToken(response.token)),
        mergeMap(() => this.getCurrentUser()),
        map((user) => ({
          token: this.getToken(),

          user,
        })),
        tap(() => {
          this.nextPage = nextPage;
          this.getCurrentMember();
        })
      );
  }

  redirectAfterLogout(): void {
    this.router.navigate(['/']);
  }

  redirectAfterLogin(): void {
    if (this.nextPage) {
      this.router.navigate([this.nextPage]);
    } else {
      this.router.navigate(['/profile']);
    }
  }

  getCurrentMember() {
    this.getMemberUserId().subscribe(
      (data) => {
        if (
          data.bmdcRegNo == null ||
          data.bmdcRegNo == '' ||
          data.bmdcRegNo == undefined
        ) {
          this.router.navigate(['/profile/profile-view']);
        } else {
          localStorage.setItem('memberId', JSON.stringify(data.id));
          this.redirectAfterLogin();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getMemberUserId() {
    return this.http.get<Member>(
      `${this.apiService.getAPIConfig().baseUrl}/api/msmmember/GetByUserId`
    );
  }

  getCurrentUser(): Observable<User> {
    const token = this.getToken();

    if (!token) {
      return of(null);
    }

    return this.http
      .get<User>(this.apiService.getEndpointUrl('current-user'))
      .pipe(
        tap((user) => {
          this.isLoggedIn.next(true);
        })
      );
  }

  logout(): void {
    this.isLoggedIn.next(false);
    localStorage.clear();
    this.redirectAfterLogout();
  }

  getToken() {
    return JSON.parse(localStorage.getItem('xalorith-access-token'));
  }

  private setToken(token: string) {
    localStorage.setItem('xalorith-access-token', JSON.stringify(token));
  }

  changePassword(model: any): Observable<any> {
    const formData = model;
    return this.http.post<any>(
      `${this.apiService.getAPIConfig().baseUrl}/api/account/changepassword`,
      formData
    );
  }
}
