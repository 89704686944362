<div class="p-4 ml-4 shadow-lg" style="border-radius: 10px; margin-left: 15px;">

    <div  class="p-2 mb-4 page-title" >Contact Us</div>
    <div class="Contact">
        <h3>Editorial Office</h3>
        <p><i class="fa fa-map-marker" aria-hidden="true"></i> Address: Room No-033, Ground floor, Dhaka Medical College Hospital, Dhaka-1000, Bangladesh.</p>
        <p><i class="fa fa-globe" aria-hidden="true"></i> Website: www.sosbjournal.org</p>
        <p><i class="fa fa-envelope" aria-hidden="true"></i> Email: jss@dhaka.net</p>
        </div>
</div>



