import { Pipe, type PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'image',
  standalone: true,
})
export class ImagePipe implements PipeTransform {
  transform(fileUrl: string, defaultImageSrc:string = null): string | null {
    if (!fileUrl) return defaultImageSrc;
    return environment.baseURL.concat(fileUrl)
  }
}
