<div class="theme top-bar-container">
  <div class="container">
    <div class="top-bar pt-4 pb-4">
      <div class="col-8">
        <p
          style="font-size: 60px; padding: 0px; margin: 0px; font-family: math"
        >
          JSS
        </p>
        <p style="font-size: 30px; font-family: math">
          Journal of Surgical Sciences
        </p>
      </div>
      <div
        class="col-4"
        style="
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        "
      >
        <div style="display: flex; justify-content: end">
          <img
            style="height: 90px"
            src="/assets/images/logo/logo-white.png"
            alt="Logo"
          />
        </div>
        <p
          style="
            margin: 0px;
            font-size: 30px;
            font-family: math;
            text-align: end;
          "
        >
          ISSN 1728-2152
        </p>
      </div>
    </div>
    <app-navbar></app-navbar>
  </div>
</div>
<div class="container">
  <div class="col-12 d-flex pt-4 pb-4">
    <div class="col-3">
      <div class="pb-4 shadow">
        <div class="current-issue">Current Issue</div>
        <a class="d-flex p-4" style="justify-content: center" [routerLink]="'/journal-view/'+ searchVm?.dataList[0]?.id">
          <img
            style="max-width: 100%; border-radius: 5px"
            [src]="searchVm?.dataList[0]?.coverPhotoUrl | image:'/assets/images/default-journal.png'"
          />
        </a>
      </div>
      <div class="pb-4 mt-4 shadow">
        <div class="archive-header">Latest Archive</div>
        <ng-container *ngIf="searchVm?.dataList?.length>0; else noList">
          <a [routerLink]="'/journal-view/'+ archive.id" *ngFor="let archive of searchVm?.dataList | slice:-10" class="archive">{{archive?.title}}</a>
        </ng-container>
        <ng-template #noList>
          <div class="archive">No Archive Found.</div>
        </ng-template>
        
      </div>
    </div>
    <div class="col-9" style="padding-left: 10px">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<app-footer></app-footer>
