<!-- <p-table
  #dt
  [rows]="searchVm?.itemsPerPage"
  [totalRecords]="searchVm?.totalRecords"
  [lazy]="true"
  (onLazyLoad)="$Dt_onLazyLoad($event)"
  [loading]="searchVm?.isLoading"
  [rowsPerPageOptions]="searchVm?.itemsPerPageOptions"
  [filterDelay]="2"
>
  <ng-template pTemplate="paginatorleft"> Display Data: <b>{{searchVm?.displayDataCount}}</b>| Total Data:
    <b>{{searchVm?.totalRecords}}</b>| Current Page: <b>{{searchVm?.currentPageNo + 1}}</b>| Total Page:
    <b>{{searchVm?.totalPageCount}}</b></ng-template>
</p-table> -->

<div  class=" mt-0 body shadow">
  <div class="header ">Journal Home</div>

  <div *ngIf="searchVm?.dataList?.length>0" class="p-4">
    <div class="content-body">
    <div  *ngFor="let data of searchVm?.dataList; let last = last" class="p-4 pb-2 pt-6 content-card">
      <div class="contents">
        <div>
          <img class="w-[200px] h-[224px] border border-black border-dotted" style="height: 224px; width: 200px;" [src]="data?.coverPhotoUrl | image:'/assets/images/default-journal.png' " />
        </div>
        <div class="right-content">
          <p class="pointer-cursor file">{{data.title}}</p>
          <p class="user-name" style="font-size: 1.23rem">{{data.description}}</p>
            <div>
              <a (click)="viewJournal(data.id)" class="navigation">View Journal</a>
<!--              <a class="navigation">Current Issue</a>-->
            </div>
        </div>
      </div>
    </div>






  </div>

  </div>
</div>
