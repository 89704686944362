import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Subject } from 'rxjs';
import { MsmArticleSubmissionSearchDto } from 'src/app/core/models/blog/articlesearch.model';
import { ApiService } from 'src/app/core/services/api.service';

import { JournalService } from 'src/app/core/services/journal.service';

import { Toast } from 'src/app/core/services/toast.service';
import { DataTablePagination } from 'src/app/core/utils/DataTable/DataTablePagination';
import { Utility } from 'src/app/core/utils/Utility';
import {SubSink} from 'subsink';
@Component({
  selector: 'app-article-submission',
  templateUrl: './article-submission.component.html',
  styleUrls: ['./article-submission.component.css']
})
export class ArticleSubmissionComponent implements OnInit {
  pageSize=100
  public searchVm: DataTablePagination<MsmArticleSubmissionSearchDto, MsmArticleSubmissionSearchDto>;
  private _subs = new SubSink();
  private destroy$ = new Subject<void>();
  constructor( private _service: JournalService,
    private toast: Toast,
    private apiService: ApiService,
  ) {
    this.searchVm = new DataTablePagination<MsmArticleSubmissionSearchDto, MsmArticleSubmissionSearchDto>(
      MsmArticleSubmissionSearchDto
    );
    this.searchVm.itemsPerPage = this.pageSize;
  }

  ngOnInit(): void {
    this._subs.sink = this._service.dataSource.subscribe((c) => {
      if (Utility.HasNoError(c)) {
        this.searchVm = c;
      }
    });

    console.log(this.searchVm)

    this._service.dataTableSearch(this.searchVm);
  }

  $Dt_onLazyLoad(event: LazyLoadEvent = null, isFilter: boolean = false): void {
    try {
      event.rows = this.pageSize;
      this._service.dataTableSearch(this.searchVm, event, isFilter);
    } catch (err) {
      this.toast.show({title: 'Warning!', description: err, type: 'warning'});
    }

  }


  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this._subs.unsubscribe();
  }

}
