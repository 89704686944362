import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Utility } from '../utils/Utility';
import { AuthService } from './auth.service';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { AppBaseService } from './app-base.service';
import { JournalPublicationMasterDto } from '../models/blog/journal.model';
import { ArticleView } from '../models/blog/article-view.mode';

@Injectable({
  providedIn: 'root'
})
export class JournalService extends AppBaseService<JournalPublicationMasterDto> {
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private authService: AuthService
  ) {
    super(http, 'acadJournalPublication');
  }


  submitArticle(model: any): Observable<any> {
    // const formData = serialize(member);
    const formData = Utility.ConvertToFormData(model);
    const url = `${this.apiService.getAPIConfig().baseUrl}/api/ArticleSubmission`;
    return this.http.post<any>(url, formData);
  }

  updateArticle(model: any): Observable<any> {
    // const formData = serialize(member);
    const formData = Utility.ConvertToFormData(model);
    const url = `${this.apiService.getAPIConfig().baseUrl}/api/ArticleSubmission/${model.id}`;
    return this.http.put<any>(url, formData);
  }

  submitArticleReview(model: any): Observable<any> {
    return this.http.post<any>(`${this.apiService.getAPIConfig().baseUrl}/api/ArticleReview`, model);
  }


  getArticles(
    model: any
  ): Observable<any> {
    // const formData = serialize(member);
    //const formData=Utility.ConvertToFormData(model);
    return this.http.post<any>(
      `${this.apiService.getAPIConfig().baseUrl}/api/ArticleSubmission/search`,
      model
    );
  }


  articleGetById(id: any) {
    return this.http.get<ArticleView>(`${this.apiService.getAPIConfig().baseUrl}/api/ArticleSubmission/${id}`);
  }

  getJournal() {
    return this.http.get<any>(`${this.apiService.getAPIConfig().baseUrl}/api/AcadJournalPublication`);
  }

  getJournals(): Observable<any> {
    return this.http.post<any>(
      `${this.apiService.getAPIConfig().baseUrl}/api/AcadJournalPublication`,null
    );
  }

  getJournalDetailById(id: any) {
    return this.http.get<JournalPublicationMasterDto>(`${this.apiService.getAPIConfig().baseUrl}/api/AcadJournalPublication/${id}`);
  }


 getReviewByArticleId(id: any) {
    return this.http.get<any>(`${this.apiService.getAPIConfig().baseUrl}/api/MsmArticleReview/GetArticleReviews?articleId=${id}`);
  }

}
