<footer  class="relative">
    <div  class="container mx-auto relative z-[2]">
        <div  class="pt-[60px] pb-[40px]">
             
            <div  class="grid px-8 xl:px-0 md:grid-cols-3 lg:grid-cols-4 gap-8">
                <div ><a  href="/"
                        class="mb-[15px] inline-block"><img 
                            src="/assets/images/logo/logo-white.png" alt="Logo" class="h-14 w-14"></a>
                    <p  class="text-white text-[13px] leading-normal">The Journal of Surgical Sciences is one of the premier clinical and laboratory-based research journals in Bangladesh. Its international readership is increasing rapidly. It features the best clinical and laboratory-based research on various disciplines of medical science to provide a place for medical scientists to relate experiences which will help others to render better patient care. </p>
                </div>
                   
            </div>
        </div>
        <div 
            class="py-[30px] border-t px-8 xl:px-0 border-[#efefef] flex flex-col md:flex-row items-center justify-between text-white">
            <p  class="text-[13px] text-center">© 2023 Society of Surgeons Bangladesh (SOSB), Journal of Surgical Sciences
                | Developed by <a  href="https://aitsgenesis.com" target="_blank"
                    class="text-[#65d3ff]"><span 
                        style="font-weight: bolder; color: whitesmoke;">AITS GENESIS</span></a></p>
            
        </div>
         
    </div>
</footer>