export class MsmArticleSubmissionSearchDto
{
    subCategoryId: string;
    subCategoryName :string
id:string;
    publicationTypeId :string
    publicationTypeName:string
    memberId :string;
    /// <summary>
    ///     Title of Journal
    /// </summary>
    title :string//2000
    abstract:string; // max
    description :string//max
    dateTime:string
    statusId:string

    // public DateTime? ApprovedOn { get; set; }
    // public DateTime? RejectedOn { get; set; }
    // public string References { get; set; }

    // public bool IsResubmitted => LastSubmissionDate != null;

    operationDate:string
    // public string RefNo { get; set; }

    reviewerId :string

    // public MsmMemberDtoFao Member { get; set; }
}
