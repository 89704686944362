import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { JournalService } from 'src/app/core/services/journal.service';
import { Utility } from 'src/app/core/utils/Utility';

@Component({
  selector: 'app-journal-view',
  templateUrl: './journal-view.component.html',
  styleUrls: ['./journal-view.component.css']
})
export class JournalViewComponent implements OnInit {
  journal: any;
  typeWiseArticle: any;

  constructor(
    private router:Router,
    private route: ActivatedRoute,
    private journalService:JournalService,
    private cdr: ChangeDetectorRef
    ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params:Params)=>{
      this.getJournalDetail(params['id'])
    })
  }

  getJournalDetail(id){
    this.journalService.getJournalDetailById(id).subscribe(data=>{
      this.journal=data;
      this.typeWiseArticle=Utility.groupBy(data.details,c=>c.publicationTypeName);
      this.cdr.detectChanges();
    })
  }
}
