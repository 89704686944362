<div class="mt-0 shadow" *ngIf="(editorialMembers$ |  async) as editorialMembers">
  <div class="header">A Message from the Chairman</div>
  <div class="p-4">
    <div class="row">
      <div class="col-md-3"><img class="w-[200px] h-[224px] border border-black border-dotted"
                                 style="height: 224px; width: 200px;" [src]="editorialMembers.Chairman[0].memberPhotoUrl | image" [alt]="editorialMembers.Chairman[0].memberName"/></div>
      <div class="col-md-8">
        <p><b>Dear Members,</b></p>
        <p>Seasons greeting for all.</p>
        <p style="text-align: justify">
          I thank all the members for sending their research works to us and
          humbly request to continue to send good quality research articles,
          review articles and case reports for the upcoming issues of the
          journal. We are trying hard to create online version of our journal
          which is a pre-requisite to be indexed and to get international
          recognition. I earnestly request our junior members to submit their
          dissertation as paper to publish in the journal which will help them
          in their career.
        </p>
      </div>
      <div class="col-md-12" style="margin-top: 40px">
        <p style="line-height: 10px">Thanking you all,</p>
        <p style="line-height: 10px"><b>{{ editorialMembers.Chairman[0].memberName }}</b></p>
        <p style="line-height: 10px">Chairman</p>
        <p style="line-height: 10px">Journal of Surgical Sciences</p>
        <p style="line-height: 10px">Society of Surgeons of Bangladesh</p>
      </div>
    </div>
  </div>
  <div class="p-4" >
    <div class="shadow" style="border-radius: 9px; padding-bottom: 20px">
      <div clss="row " style="border-radius: 20px">
        <h3 class="editorial-board">
          Editorial Board
        </h3>
        <table class="table table-striped table-bordered table-hover" style="
            margin: 0 auto;
            font-size: 16px;
            font-weight: bold;
            width: 100%;
            max-width: 867px;
          ">
          <tbody>
            <tr *ngFor="let designation of object.keys(editorialMembers)">
              <td style="
                display: block;
                border: none;
                background-color: #169ad8;
                color: #fff;
                font-size: 18px;
              ">
                {{designation}}
              </td>
              <td>
                <ng-container
                  *ngFor="let editorialMember of editorialMembers[designation]">{{editorialMember.memberName}}
                  <br /></ng-container>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
