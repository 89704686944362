import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './core/home/home.component';
import { LandingPageComponent } from './public/landing-page/landing-page.component';
import { AboutUsComponent } from './public/about-us/about-us.component';
import { NavbarComponent } from './core/navbar/navbar.component';
import { ArchiveComponent } from './public/archive/archive.component';
import { EditorialBoardComponent } from './public/editorial-board/editorial-board.component';
import { ArticleSubmissionComponent } from './public/article-submission/article-submission.component';
import { ContactUsComponent } from './public/contact-us/contact-us.component';
import { LoginComponent } from './public/login/login.component';
import { JournalViewComponent } from './public/journal-view/journal-view.component';
import { AirticleViewComponent } from './public/airticle-view/airticle-view.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './core/middleware/token.interceptor';
import { ErrorInterceptor } from './core/middleware/error.interceptor';
import { HotToastModule } from '@ngneat/hot-toast';
import { TableModule } from 'primeng/table';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {ImagePipe} from "./core/pipes/image.pipe";

import {ChipModule} from "primeng/chip";
import {TooltipModule} from "primeng/tooltip";
import {DividerModule} from "primeng/divider"; 
import { FooterComponent } from './core/footer/footer.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    LandingPageComponent,
    AboutUsComponent,
    ArchiveComponent,
    EditorialBoardComponent,
    ArticleSubmissionComponent,
    ContactUsComponent,
    LoginComponent,
    JournalViewComponent,
    AirticleViewComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    TableModule, 
    FooterComponent,
    HotToastModule.forRoot({
      position: 'top-center',
      duration: 5000,
      dismissible: true,
      autoClose: true,
    }),
    ImagePipe,
    ChipModule,
    TooltipModule,
    DividerModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
