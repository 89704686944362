<div  class=" mt-0 shadow ">
    <div class="header">Journal of Surgical Sciences > Archives</div>
    <div class="p-4">
      <div *ngFor="let data of searchVm?.dataList; let i = index" title="{{data.title}}">
        <div (click)="viewJournal(data.id)" class="contents shadow pointer-cursor">
          <p style="margin:0px">
            <!-- <i class="fas fa-file-word"></i> -->
            <i class="far fa-file-pdf"></i> {{data.title}}
          </p>
          <!-- <p>PDF</p> -->
        </div>


      </div>

    </div>
  </div>

