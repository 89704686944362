import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import { Subject } from 'rxjs';
import { JournalPublicationMasterDto } from 'src/app/core/models/blog/journal.model';
import { JournalService } from 'src/app/core/services/journal.service';
import { Toast } from 'src/app/core/services/toast.service';
import { DataTablePagination } from 'src/app/core/utils/DataTable/DataTablePagination';
import { Utility } from 'src/app/core/utils/Utility';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArchiveComponent implements OnInit,OnDestroy {
  public searchVm: DataTablePagination<JournalPublicationMasterDto, JournalPublicationMasterDto>;
  private _subs = new SubSink();
  private destroy$ = new Subject<void>();
  pageSize=100;

  list: JournalPublicationMasterDto[];
  constructor(
    private router:Router,
    private _service: JournalService,
    private toast: Toast,
    private cdr: ChangeDetectorRef
    ) {
      this.searchVm = new DataTablePagination<JournalPublicationMasterDto, JournalPublicationMasterDto>(
        JournalPublicationMasterDto
      );
      this.searchVm.itemsPerPage = this.pageSize;


    }

  ngOnInit(): void {
     this._subs.sink = this._service.dataSource.subscribe((c) => {
        if (Utility.HasNoError(c)) {
            this.searchVm = c;
          this.list=this.searchVm.dataList
          this.cdr.detectChanges();

        }
      });




    this._service.dataTableSearch(this.searchVm);


  }




  viewJournal(id){


    this.router.navigate(["/journal-view/"+id])
  }



  $Dt_onLazyLoad(event: LazyLoadEvent = null, isFilter: boolean = false): void {
    try {
      event.rows = this.pageSize;
      this._service.dataTableSearch(this.searchVm, event, isFilter);
      console.log(this.searchVm)
    } catch (err) {
      this.toast.show({title: 'Warning!', description: err, type: 'warning'});
    }

  }


  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this._subs.unsubscribe();
  }


}
