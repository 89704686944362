import {Component, OnInit, signal} from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Observable, catchError, combineLatest, map, of, switchMap } from "rxjs";
import { JournalService } from "../../core/services/journal.service";
import { Toast } from "../../core/services/toast.service";
import { ArticleView } from 'src/app/core/models/blog/article-view.mode';

type ArticleViewQueryParam  = {section:any, publishedOn:any, isOpen:boolean, doi:any,keywords: any, issue:any}
type ArticleViewResponse = Readonly<ArticleView & ArticleViewQueryParam >

@Component({
  selector: 'app-airticle-view',
  templateUrl: './airticle-view.component.html',
  styleUrls: ['./airticle-view.component.css']
})
export class AirticleViewComponent implements OnInit {

  article$: Observable<ArticleViewResponse>

  constructor(
    private activatedRoute: ActivatedRoute,
    private journalService: JournalService,
    private toast: Toast) {
  }

  ngOnInit(): void {
    this.article$ = combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
      .pipe(
        map(([params, queryParams]) => {
          return {
            articleId: params['id'],
            publishedOn: queryParams['publishedOn'],
            section: queryParams['section'],
            isOpen: Boolean(queryParams['isOpen']),
            doi: queryParams['doi'],
            keywords: queryParams['keywords'],
            issue: queryParams['issue'],
          }
        }),
        switchMap((obj) => {
          return this.journalService.articleGetById(obj.articleId).pipe(
            map((response) => {
              return  {publishedOn:obj.publishedOn, section: obj.section, isOpen: obj.isOpen,doi: obj.doi ,keywords:obj.keywords,issue: obj.issue ,...response} as const;
            })
          )
        }), catchError((error) => {
          this.toast.show({ title: 'error!', description: error, type: 'warning' });
          return of(null)
        })
      )
  }

  onDownload(){

  }
}
