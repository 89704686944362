import { inject, Injectable } from '@angular/core';
import { Message, MessageType } from '../models/message';
import {style} from "@angular/animations";
import { HotToastService } from '@ngneat/hot-toast';

@Injectable({
  providedIn: 'root'
})
export class Toast {
   private toaster: HotToastService = inject(HotToastService);

  constructor() { }

  public show(message: Message): void {
    const type: "info" | "error" | "success" | "warning" = message.type ?? 'info';

    if ( type === 'error' ) {
      this.toaster.error(message.description);
    } else if ( type === 'success' ) {
      this.toaster.success(message.description);
    } else if ( type === 'warning' ) {
      this.toaster.warning(message.description);
    } else {
      this.toaster.info(message.description);
    }
  }
}
