import { Injectable } from '@angular/core';
import { IAppSettings } from '../models/i-app-settings';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, map } from 'rxjs';
import { IApiEndpoint } from '../models/i-api-endpoint';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

    private apiConfig: IAppSettings ={
      baseUrl: environment.baseURL,
      organizationId:environment.organizationId
    };

    constructor(private http: HttpClient) {
    }


    getAPIConfig(): IAppSettings {
      return this.apiConfig;
    }

    fetchAndSetAPIConfig(): Observable<IAppSettings> {
      return forkJoin([this.fetchAPIConfig(), this.fetchApiEndpoint()]).pipe(
        map(([appSetting, endPoints]) => {
          const config: IAppSettings = {
            baseUrl : appSetting.baseUrl,
            organizationId : appSetting.organizationId,
            endpoints : endPoints
          };
          this.setAPIConfig(config);
          return config;
        })
      );

    }


    // Fetch and set the API configuration
    // fetchAndSetAPIConfig(): Observable<IAppSettings> {
    //   return this.fetchAPIConfig().pipe(
    //     switchMap(config => {
    //       return this.fetchApiEndpoint().pipe(
    //         map(endpoints => {
    //           config.endpoints = endpoints;
    //           this.setAPIConfig(config);
    //           return config;
    //         })
    //       );
    //     })
    //   );
    // }

    // Fetch the API configuration from 'app-settings.json'
    private fetchAPIConfig(): Observable<IAppSettings> {
      return this.http.get<IAppSettings>('/assets/api/app-settings.json');
    }

    // Fetch the API endpoint from 'api-endpoint.json'
    private fetchApiEndpoint(): Observable<IApiEndpoint> {
      return this.http.get<IApiEndpoint>('/assets/api-endpoint.json');
    }

    // Set the API configuration in the service for later use
    private setAPIConfig(config: IAppSettings): void {
      this.apiConfig = config;
    }


    getEndpointUrl(endpoint: keyof IApiEndpoint): string {
      const url = this.apiConfig.baseUrl + this.getEndpoint(endpoint);
      return url;
    }


    private getEndpoint(endpoint: keyof IApiEndpoint): string {
      // let base = this.apiConfig.baseUrl;
      // let endpoints = this.apiConfig.endpoints;


      let url = '';
      //if (this.apiConfig.endpoints instanceof IApiEndpoint) {
      if (this.apiConfig.endpoints) {
        url = this.apiConfig.endpoints[endpoint];
      } else {
        this.http.get<IApiEndpoint>('/assets/api-endpoint.json').subscribe(value => {
          if (value) {
            this.apiConfig.endpoints = value;
            url = this.apiConfig.endpoints[endpoint];
          }
        });
      }

      return url;
    }
  }
