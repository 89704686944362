<div  class=" mt-0 shadow ">
    <div class="header">Article Submission</div>
    <div class="p-4">
        <form>
            <div class="form-group">
                <label for="username">Username</label>
                <input type="text" class="form-control" id="username"  placeholder="username">
               
              </div>
              <div class="form-group">
                <label for="password">Password</label>
                <input type="password" class="form-control" id="password"  placeholder="password">
               
              </div>
           
           
            <button type="submit" class="btn">Login</button>
          </form>
    </div>
  </div>
  


