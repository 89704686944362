import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataTablePagination } from '../utils/DataTable/DataTablePagination';
import { HttpClient } from '@angular/common/http';
import { Utility } from '../utils/Utility';
import { LazyLoadEvent } from 'primeng/api/lazyloadevent';
import { BaseService, Resource } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class AppBaseService <T extends Resource> extends BaseService<T> {

	public dataSource: BehaviorSubject<DataTablePagination<any, any>> = new BehaviorSubject<DataTablePagination<any, any>>(null);
	public currentData: BehaviorSubject<T>;
	public currentDataView: BehaviorSubject<T>;
	public currentDataListView: BehaviorSubject<T[]>;

	constructor(public _http: HttpClient,@Inject(String) public _endPoint: string) {
		super(_http, _endPoint);
		this.dataSource = new BehaviorSubject<DataTablePagination<any, any>>(null);
		this.currentData = new BehaviorSubject<T>(null);
		this.currentDataView = new BehaviorSubject<T>(null);
		this.currentDataListView = new BehaviorSubject<T[]>(null);
	}

	dataTableSearch(searchVm: any = null, event: LazyLoadEvent = null, isFilter: boolean = false, searchUrl: string = 'Search'): any {

		searchVm = Utility.GetDtModelData(searchVm, event);
		if (isFilter && (!Utility.HasNoError(searchVm.filter))) {
			throw new Error('Sorry! No Data Found to Search');
		}
		if (isFilter && (Utility.HasNoError(searchVm.filter) && searchVm.filter.length <= 1)) {
			throw new Error('Sorry! Min Two Characters Needed to Search/ Filter');
		}
		if (!isFilter && (Utility.HasNoError(searchVm.filter))) {
			searchVm.filter = null;
		}
		searchVm.isLoading = true;
		searchVm.dataList = [];
		super.search(searchVm, searchUrl).subscribe((rData: DataTablePagination<any, any>) => {
				if (Utility.HasNoError(rData)) {
					//searchVm = rData;
					this.dataSource.next(rData);
				}
				searchVm.isLoading = false;

			}
			, (e: Response) => {
				searchVm.isLoading = false;

			//	alert(e?.['error']?.PublicMessage ?? "Error");
			}
		);
	}




	setData(entity: T): void {
		this.currentData.next(entity);
	}

	setDataView(entity: T): void {
		this.currentDataView.next(entity);
	}

	setDataListView(entities: T[]): void {
		this.currentDataListView.next(entities);
	}

	clearDataSource(): void {
		this.dataSource.next(null);
	}

}
