import { IDataTablePagination } from './IDataTablePagination';

export class DataTablePagination<TSearchVm, TEntityModel> implements IDataTablePagination<TSearchVm, TEntityModel> {


	constructor(model?: any) {
		if (this.hasNoError(model)) {

			if (this.isObject(model)) {
				this.searchModel = model;
			} else {
				this.searchModel = new model();
			}

		}

	}

	id?: string;

	userId?: string;
	serialNo: number = 0;

	sortField: string = '';
	sortOrder: string = '';
	filter: string = '';

	displayDataCount: number = 0;
    totalRecords: number = 0;
	currentPageNo: number = 0;
	totalPageCount: number = 0;
	itemsPerPage: number = 25;
	itemsPerPageOptions = [25, 50, 75, 100, 150, 200];
	itemsPerPageReportOptions = [50, 100, 200, 300, 400, 500];


	dataList: TEntityModel[] = [];
	searchModel: TSearchVm = null;

	startPoint: number = 0;
	endPoint: number = 0;

	isLoading: boolean;



  private isObject(data: any): boolean {
    if (data === null) {
      return false;
    }
    return (typeof data === 'object');
  }


  private hasNoError(data: any) {
    if (data || data === 0) {
      return true;
    }
    return !(data == null || data === '' || data === undefined);
  }
}


