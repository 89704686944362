import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { SubSink } from 'subsink';
import { JournalPublicationMasterDto } from '../models/blog/journal.model';
import { DataTablePagination } from '../utils/DataTable/DataTablePagination';
import { Router } from '@angular/router'; 
import { JournalService } from '../services/journal.service';
import { Toast } from '../services/toast.service';
import { Utility } from '../utils/Utility';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit, OnDestroy {
  public searchVm: DataTablePagination<JournalPublicationMasterDto, JournalPublicationMasterDto>;
  private _subs = new SubSink();
  private destroy$ = new Subject<void>();
  pageSize = 100;
  list: JournalPublicationMasterDto[];
  constructor(
    private router: Router,
    private _service: JournalService,
    private toast: Toast,
    private cdr: ChangeDetectorRef
  ) {
    this.searchVm = new DataTablePagination<JournalPublicationMasterDto, JournalPublicationMasterDto>(
      JournalPublicationMasterDto
    );
    this.searchVm.itemsPerPage = this.pageSize;
  }
  ngOnInit(): void {
    this._subs.sink = this._service.dataSource.subscribe((c) => {
      if (Utility.HasNoError(c)) {
        this.searchVm = c;
        this.list = this.searchVm.dataList
        this.cdr.detectChanges();

      }
    });
    this._service.dataTableSearch(this.searchVm);
  }

  viewJournal(id) {
    this.router.navigate(["/journal-view/" + id])
  }



  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this._subs.unsubscribe();
  }

}
