import { HttpClient, HttpHeaders } from "@angular/common/http";
import { IAppSettings } from "../models/i-app-settings";
import { Observable, map, switchMap } from "rxjs";
import { Utility } from "../utils/Utility";
import { IDataTablePagination } from "../utils/DataTable/IDataTablePagination";


export class Resource {
  id: string;
}
export abstract class BaseService <T extends Resource> {
    // private _entity: T;
    // private _entityList: T[];
    baseUrl = '';
    private apiConfig: IAppSettings;
    protected constructor(private _httpClient: HttpClient, private _endpoint: string) {
      //this.getBaseURL();
    }

    private getFullUrl(): Observable<string> {
      return this.getBaseURL().pipe(
        map(baseURL => {
          if (baseURL && baseURL.length > 5) {
            return `${baseURL}/api/${this._endpoint}`;
          }
          return `${this.getBaseURL()}/api/${this._endpoint}`;
        })
      );
    }

    private getBaseURL(): Observable<string> {
      return this._httpClient.get('/assets/api/app-settings.json').pipe(
        map((appSettings: any) => appSettings.baseUrl)
      );
    }


    public get requestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; } } {
      const headers = new HttpHeaders(
        {
          responseType: 'blob'
          //'Access-Control-Allow-Origin':'*',
          /// Authorization: 'Bearer ' + this.authService.accessToken,
          //Authorization: '',
          //	'Accept': 'application/json, text/plain, charset=utf-8,'
        },
      );
      return {headers};
    }


    public add(entity: T, isFileIncluded: boolean = false): Observable<T> | any {
      return this.getFullUrl().pipe(
        switchMap(fullUrl => {
            if (isFileIncluded) {
              const formData = Utility.ConvertToFormData(entity);
              return this._httpClient.post(`${fullUrl}`, formData, this.requestHeaders);
            }
            return this._httpClient.post<T>(`${fullUrl}`, entity, this.requestHeaders);
          }
        )
      );


    }

    public addFormData(formData: FormData, headers: HttpHeaders): Observable<T> | any {
      return this.getFullUrl().pipe(
        switchMap(fullUrl => {
            return this._httpClient.post(`${fullUrl}`, formData, this.requestHeaders);
          }
        )
      );
    }

    public addRange(entityList: T[]): Observable<T> | any {
      return this.getFullUrl().pipe(
        switchMap(fullUrl => {
            return this._httpClient.post<T>(`${fullUrl}/PostRange`, entityList, this.requestHeaders);

          }
        )
      );
    }

    public update(entity: T, isFileInclude: boolean = false): Observable<T> | any {
      return this.getFullUrl().pipe(
        switchMap(fullUrl => {
            if (isFileInclude) {
              const formData = Utility.ConvertToFormData(entity);
              if (entity.id == null) {
                return Utility.ThrowError('Sorry! No Data Found to Update');
              }
              return this._httpClient.put(`${fullUrl}/${entity.id}`, formData, this.requestHeaders);
            }
            return this._httpClient.put<T>(`${fullUrl}/${entity.id}`, entity, this.requestHeaders);

          }
        )
      );
    }

    public updateFormData(id: number, formData: FormData, headers: HttpHeaders): Observable<T> | any {
      if (!(id > 0)) {
        return Utility.ThrowError('Sorry! No Data Found to Update');
      }

      return this.getFullUrl().pipe(
        switchMap(fullUrl => {
            return this._httpClient.put(`${fullUrl}/${id}`, formData, this.requestHeaders);
          }
        )
      );

    }

    public updateRange(entityList: T[]): Observable<T> | any {
      return this.getFullUrl().pipe(
        switchMap(fullUrl => {
            return this._httpClient.put<T>(`${fullUrl}`, entityList, this.requestHeaders);
          }
        )
      );
    }

    public partialUpdate(entity: T): Observable<T> {
      return this.getFullUrl().pipe(
        switchMap(fullUrl => {
            return this._httpClient.patch<T>(`${fullUrl}/${entity.id}`, entity, this.requestHeaders);

          }
        )
      );

    }

    public search(dataTable: IDataTablePagination<any, any>, searchUrl: string = 'Search'): Observable<IDataTablePagination<any, any>> | any {
      return this.getFullUrl().pipe(
        switchMap(fullUrl => {
            return this._httpClient.post<IDataTablePagination<any, any>>(`${fullUrl}/${searchUrl}`, dataTable, this.requestHeaders)
          }
        )
      );

      //return this._httpClient.post<IDataTablePagination<any, any>>(`${this.getFullUrl()}/${searchUrl}`, dataTable, this.requestHeaders);
    }

    public getById(id: string): Observable<T> {
      return this.getFullUrl().pipe(
        switchMap(fullUrl => {
            return this._httpClient.get<T>(`${fullUrl}/${id}`, this.requestHeaders);
          }
        )
      );
    }


    public isExist(actionName: string, queryParams: string): Observable<any> {
      return this.getFullUrl().pipe(
        switchMap(fullUrl => {
            return this._httpClient.get<T>(`${fullUrl}/${actionName}?${queryParams}`, this.requestHeaders);
          }
        )
      );

    }

    public delete(id: string): Observable<any> {
      return this.getFullUrl().pipe(
        switchMap(fullUrl => {
            return this._httpClient.delete<T>(`${fullUrl}/${id}`, this.requestHeaders);

          }
        )
      );
    }

    public moveToBin(id: string): Observable<any> {
      return this.getFullUrl().pipe(
        switchMap(fullUrl => {
            return this._httpClient.delete<T>(`${fullUrl}/${id}/true`, this.requestHeaders);
          }
        )
      );
    }

    public removeRange(ids: string[]): Observable<any> {
      return this.getFullUrl().pipe(
        switchMap(fullUrl => {
            return this._httpClient.delete<T>(`${fullUrl}/${ids}`, this.requestHeaders);
          }
        )
      );
    }

  }
