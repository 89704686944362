<div *ngIf="journal" class=" mt-0 body shadow">
  <div class="header">{{ journal.title }}</div>
  <div class="p-4">
    <div class="content-body">
      <div class="p-4  pb-2 pt-6 content-card">
        <div class="grid">
          <div class="contents">
            <div class="flex-grow-1 d-grid gap-2">
              <a download  [href]="journal.contentFileUrl | image" class="message" > View PDF</a>
              <!-- <a [routerLink]="'/article-view/'+article.articleId"
                 [queryParams]="{'published':article?.publishedOn, section:article?.publicationTypeName, isOpen: +article?.isOpenForAll, doi: article?.doi, keywords: article?.keyWords, issue: journal?.title}" class="message">
                {{ article.articleTitle }}</a>
              <div class="user-name d-flex gap-2 flex-wrap" *ngIf="article?.authors">
                <p-chip styleClass="custom-chip" [pTooltip]="author?.name + ', ' +(author?.email ?? 'No email found.')" tooltipPosition="bottom"
                        *ngFor="let author of article?.authors" [label]="author?.name"
                        [image]="author?.photoUrl | image:'/assets/images/user-default.jpeg'"></p-chip>
              </div> -->
            </div>
            <div class="right-content">
              <!-- <p class="pointer-cursor file"><i class="fa-regular fa-file-powerpoint "></i> PDF</p> -->
              <!-- <p class="user-name fw-medium">{{ article.pageInfo }}</p> -->
            </div>
          </div>
          <!-- <p-divider type="solid" align="bottom"></p-divider> -->
        </div>
        <p class="content-title">CONTENT</p>
      </div>
      <div *ngFor="let type of typeWiseArticle; let i = index" class="p-4  pb-2 pt-6 content-card">
        <div *ngFor="let article of type.items; let j = index" class="grid">
          <div class="contents">
            <div class="flex-grow-1 d-grid gap-2">
              <a [routerLink]="'/article-view/'+article.articleId"
                 [queryParams]="{'published':article?.publishedOn, section:article?.publicationTypeName, isOpen: +article?.isOpenForAll, doi: article?.doi, keywords: article?.keyWords, issue: journal?.title}" class="message">
                {{ article.articleTitle }}</a>
              <div class="user-name d-flex gap-2 flex-wrap" *ngIf="article?.authors">
                <p-chip styleClass="custom-chip" [pTooltip]="author?.name + ', ' +(author?.email ?? 'No email found.')" tooltipPosition="bottom"
                        *ngFor="let author of article?.authors" [label]="author?.name"
                        ></p-chip>
              </div>
            </div>
            <div class="right-content">
              <!-- <p class="pointer-cursor file"><i class="fa-regular fa-file-powerpoint "></i> PDF</p> -->
              <p class="user-name fw-medium">{{ article.pageInfo }}</p>
            </div>
          </div>
          <p-divider type="solid" align="bottom"></p-divider>
        </div>
        <p class="content-title">{{ type.key }}</p>
      </div>
    </div>
  </div>
</div>
