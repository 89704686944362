import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ApiService } from '../services/api.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private apiService: ApiService
  ) {
  }

  intercept( request: HttpRequest<unknown>, next: HttpHandler ): Observable<HttpEvent<unknown>> {
    const token: string | null = this.authService.getToken();
    if ( !token ) {
      const organizationId = this.apiService.getAPIConfig()?.organizationId;
      let clonedRequest = request.clone();

      if ( organizationId ) {
        clonedRequest = request.clone( {
          setHeaders: {
            OrganizationId: organizationId
          }
        } );
      }

      return next.handle( clonedRequest );
    }

    if ( this.isJWTExpired( token ) ) {
      this.authService.logout();
      return next.handle( request );
    }

    const clonedRequest = request.clone( {
      setHeaders: {
        Authorization: `Bearer ${ token }`,
      }
    } );
    return next.handle( clonedRequest );
  }


  private isJWTExpired(token: string): boolean {
    if (!token) {
      return true;
    }

    const jwt = JSON.parse(atob(token.split('.')[1]));
    const expires = jwt.exp;
    const now = Date.now() / 1000;

    return now > expires;
  }

}
