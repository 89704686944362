import { MsmArticleSubmissionSearchDto } from "./articlesearch.model";

export class JournalPublicationMasterDto{
  id: string;
  title: string;
  operationDate: Date;
  refNo: string;
  publicationOn: Date;
  issN: string;
  licenseInfo: string;
  statusId: number;
  statusName: string;
  fileUrl: string;
  file: File;

  details: JournalPublicationDetailsDto[] = [];
}

export class JournalPublicationDetailsDto{
  id: string;
  journalId: string;
  journal: JournalPublicationMasterDto;
  publicationTypeId: string;
  publicationTypeName: string;
  articleId: string;
  articleTitle: string;
  article: MsmArticleSubmissionSearchDto;
  doI: string;
  keyWords: string;
  pageInfo: string;
  isOpenForAll: boolean;
  authors: any[];
}
