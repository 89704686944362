import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Toast } from '../services/toast.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private toast: Toast,
  ) {
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle( request ).pipe(
      catchError( ( error: HttpErrorResponse ) => {
        const simpleError: string = stringifyError( error );
console.log(error);
console.log(simpleError)
if(!( error.status === HttpStatusCode.Unauthorized ||
  error.status === HttpStatusCode.Forbidden))
        this.toast.show( {
          title: 'Error!',
          description: simpleError,
          type: 'error'
        } );

        const isAuthError =
          error.status === HttpStatusCode.Unauthorized ||
          error.status === HttpStatusCode.Forbidden;

        return throwError( simpleError );
      } ),
    );
  }
}

function stringifyError( error: HttpErrorResponse ): string {
  switch ( error.status ) {
    case HttpStatusCode.Forbidden:
    case HttpStatusCode.Unauthorized:
      return `You are not authorized to make the request or your session is expired.
              Please, make sure you are logged in and have the permission to make the request.
              You will be logged out if you are already logged in (your session is expired).
             `;

    case HttpStatusCode.MethodNotAllowed:
      return `The request method is wrong!
              Please, contact with engineering team to address this issue.
             `;

    case HttpStatusCode.GatewayTimeout:
      return `Request is taking too much time. Probably your internet connection is slow.
              Please, retry in a minute. If this error still occurs,
              contact with engineering team to address the issue.
             `;

    case HttpStatusCode.Gone:
      return `Something is wrong in request URL.
              Please, contact with engineering team to address this issue.
             `;

    case HttpStatusCode.NotFound:
      return `The data you are expecting or URL you are requesting does not exists.
              If you are sure about the existence, please contact with admin to address the issue.
             `;

    case HttpStatusCode.NotImplemented:
      return `Your requested method is not implemented! If you are not sure about this error,
              contact with engineering team to address the issue.
             `;

    default: {
      const _error = error as any;
      let message = _error?.error?.error_description ?? _error.error?.PublicMessage ?? JSON.stringify(_error);

      const offline = !navigator.onLine;
      if ( offline ) {
        message =
          'My be you are in offline! Please, check internet connection.';
      }

      return message;
    }
  }
}
