<p-table
  #dt
  [rows]="searchVm?.itemsPerPage"
  [totalRecords]="searchVm?.totalRecords"
  [lazy]="true"
  (onLazyLoad)="$Dt_onLazyLoad($event)"
  [loading]="searchVm?.isLoading"
  [rowsPerPageOptions]="searchVm?.itemsPerPageOptions"

>
  <ng-template pTemplate="paginatorleft"> Display Data: <b>{{searchVm?.displayDataCount}}</b>| Total Data:
    <b>{{searchVm?.totalRecords}}</b>| Current Page: <b>{{searchVm?.currentPageNo + 1}}</b>| Total Page:
    <b>{{searchVm?.totalPageCount}}</b></ng-template>
</p-table>

<div  class=" mt-0 shadow ">
    <div class="header">Article Submission</div>
    <div class="p-4">
        <form>
            <div class="form-group">
                <label for="name">Name</label>
                <input type="text" class="form-control" id="name"  placeholder="name">

              </div>
            <div class="form-group">
              <label for="email">Email</label>
              <input type="email" class="form-control" id="email"  placeholder="email">

            </div>
            <div class="form-group">
                <label for="subject">Subject</label>
                <input type="text" class="form-control" id="subject"  placeholder="subject">

              </div>
              <div class="form-group">
                <label for="article">Article</label>
                <input type="file" class="form-control" id="article"  placeholder="article">

              </div>

            <div class="form-group">
              <label for="message">Message</label>
              <textarea type="text" class="form-control" id="message" placeholder="message"></textarea>
            </div>

            <button type="submit" class="btn">Submit</button>
          </form>
    </div>
  </div>


