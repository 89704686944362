import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditorialMembersService } from 'src/app/core/services/editorial-members.service';

@Component({
  selector: 'app-editorial-board',
  templateUrl: './editorial-board.component.html',
  styleUrls: ['./editorial-board.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorialBoardComponent   {

  object = Object;
  constructor(private editorialMemberService: EditorialMembersService) { }
  editorialMembers$ = this.editorialMemberService.getEditorialMembers()

}
