import { Component, OnInit } from '@angular/core';
import { Subject, catchError, filter, forkJoin, mergeMap, takeUntil, tap } from 'rxjs';
import { ApiService } from './core/services/api.service';
import { AuthService } from './core/services/auth.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'sosb-journal';
  loading = true;

  private destroy$ = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router
  ) {
  }

  ngOnInit() {
    forkJoin([
      this.apiService.fetchAndSetAPIConfig()
    ])
      .pipe(
        mergeMap(() => this.authService.getCurrentUser()),
        tap(() => this.loading = false),
        catchError(() => {
          this.loading = false;
          this.authService.logout();
          return [];
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        tap(event => {
          this.scrollTop();
        })
      )
      .subscribe();
  }

  scrollTop() {
    window.scrollTo( 0, 0 );
  }

}
