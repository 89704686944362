import { LazyLoadEvent } from 'primeng/api/lazyloadevent';
import {IDataTablePagination} from "./IDataTablePagination";
import {Utility} from "../Utility";
import {DataTablePagination} from "./DataTablePagination";



export class DataTableUtility {
	static GetDtModelData(searchVm: IDataTablePagination<any, any>, event: LazyLoadEvent): any {
		if (Utility.HasAnyError(searchVm)) {
			searchVm = new DataTablePagination<any, any>();
		}

		if (Utility.HasNoError(event)) {
			searchVm.filter = event.globalFilter;
			searchVm.itemsPerPage = event.rows;
			searchVm.currentPageNo = (event.first / event.rows);
			searchVm.sortField = event.sortField;
			searchVm.sortOrder = event.sortOrder === 1 ? 'A' : 'D';
			searchVm.dataList = [];
		}
		return searchVm;
	}
}

